'use client'

import Image from 'next/image'
import Button from 'components/global/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { useState } from 'react'
import { useSessionStorage } from 'utils/hooks'
import { event } from 'utils/gtm'

import Fondo from './mosca-etiquetas.png'

const MoscaEtiquetas = ({ section, tipologia }) => {
    const [displayMoscaEtiquetas, setDisplayMoscaEtiquetas] = useSessionStorage('mosca-etiquetas', true)
    const [display, setDisplay] = useState(displayMoscaEtiquetas)

    if (section !== 'listado' && section !== 'home' && section !== 'coches') return <></>

    if (tipologia !== 'empresas' && tipologia !== 'autonomos' && tipologia !== 'particulares') {
        return <></>
    }

    const handleClick = () => {
        setDisplay(false)
        setDisplayMoscaEtiquetas(false)
        event({ event: 'gaEvent', location: section, event_name: 'banner', detail: 'mosca - restricciones - esconder' })
    }

    const handleClickButton = (value) => {
        setDisplay(false)
        setDisplayMoscaEtiquetas(false)
        event({ event: 'gaEvent', location: section, event_name: 'banner', detail: 'mosca - restricciones - ' + value })
    }

    const link = '/renting/?bajadaPrecio=true&entregaRapida=true&source=restricciones-interes'

    return (
        <>
            {display && (
                <div
                    className="invisible fixed bottom-[10%] right-0 grid h-[323px] w-[612px] grid-cols-2 xl:visible"
                    id="mosca-restricciones"
                >
                    <Image src={Fondo} alt="" fill objectFit="cover" />

                    <div className="absolute right-4 top-4 z-50 cursor-pointer">
                        <FontAwesomeIcon icon={faCircleXmark} className="text-2xl text-white" onClick={handleClick} />
                    </div>
                    <div className="absolute left-8 top-12 max-w-[16rem] space-y-4 text-white">
                        <div className="text-xl leading-none">
                            Dile adiós a las restricciones y circula sin preocupaciones.
                        </div>
                        <div className="text-sm">
                            A partir del 1 de enero de 2024, si no tienes etiqueta medioambiental, no podrás circular en
                            ciudades con Zonas de Bajas Emisiones (ZBE).
                        </div>
                        <div className="pt-8 text-center">
                            <Button
                                href={link}
                                onClick={() => handleClickButton('me interesa')}
                                variant="default"
                                size="xl"
                                className="px-8"
                            >
                                VER OFERTAS
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default MoscaEtiquetas
