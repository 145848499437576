"use client"

import Link from 'next/link'
import { event } from 'utils/gtm'

export default function EventLink({ children, "data-event": dataEvent, ...props }) {
  const handleClick = () => {
    if (dataEvent) event(dataEvent)
  }
  return <Link {...props} onClick={handleClick}>
    {children}
  </Link>
}
