'use client'
import { useSessionStorage } from 'utils/hooks'
import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

export default function SessionStorage() {
    const searchParams = useSearchParams()
    const [origin, setOrigin] = useSessionStorage('origin', null)

    useEffect(() => {
        let source = searchParams.get('utm_source')
        const campaign = searchParams.get('utm_campaign')
        const medium = searchParams.get('utm_medium')
        const term = searchParams.get('utm_term')
        if (searchParams.get('source')) {
            source = searchParams.query?.['source']
        }

        let update = false

        if (origin === null) {
            const newOrigin = {
                source: null,
                campaign: null,
                medium: null,
                term: null,
            }
            if (campaign) {
                newOrigin['campaign'] = campaign
                update = true
            }
            if (medium) {
                newOrigin['medium'] = medium
                update = true
            }
            if (term) {
                newOrigin['term'] = term
                update = true
            }
            if (source) {
                newOrigin['source'] = source
                update = true
            }
            if (update) {
                console.log('Setting Origin', newOrigin)
                setOrigin(newOrigin)
            } else {
                console.log('No need to setup Origin', origin)
            }
        } else {
            console.log('Not setting Origin as already defined')
        }
    })

    return <></>
}
