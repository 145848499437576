'use client'
import { useRouter } from 'next/navigation'
import { getZapierEndpoint, convertFormToJSON } from 'utils/forms'
import { event } from 'utils/gtm'

export default function FooterNewsLetterForm({ section }) {
    const router = useRouter()

    const handeClickNewsletter = async (e) => {
        e.preventDefault()
        const zapier = getZapierEndpoint(null, null, 'Suscribirme')
        let values = convertFormToJSON(e.target)
        values.OptInMarketing === 'on' ? (values.OptInMarketing = 'true') : (values.OptInMarketing = 'false')

        try {
            await fetch(zapier.endpoint, {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            })
            event({ event: 'gaEvent', location: `${section}_footer`, event_name: `newsletter`, detail: 'ok' })
            router.push('/message/')
        } catch (error) {
            alert('Error enviado el formulario.')
            console.log('*** ERROR ***', error)
        }
    }

    return (
        <>
            <form method="POST" onSubmit={handeClickNewsletter}>
                <div className="font-semibold">Suscríbete a nuestra newsletter</div>
                <div className="my-3">
                    <input type="hidden" name="Locale" value="es-es" />
                    <input
                        type="hidden"
                        name="EventDefinitionKey"
                        value="APIEvent-eadd2f56-b96b-3ea5-930c-3b3c389186b5"
                    />
                    <input type="hidden" name="RequestType" value="Suscribirme Web" />

                    <input
                        type="email"
                        name="EmailAddress"
                        placeholder="Escríbe tu correo electrónico"
                        className="bg-lplightorange block w-full max-w-full rounded-md border-white text-white placeholder-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm md:inline-block md:w-80"
                        required
                    />
                    <input
                        type="submit"
                        value="Enviar"
                        className="text-lpprimary mx-auto mt-4 block w-full rounded-full border border-white bg-white px-10 py-2 text-sm font-semibold uppercase leading-4 shadow-sm hover:bg-gray-50 md:mx-0 md:ml-5 md:mt-0 md:inline-block lg:w-auto"
                    />
                </div>
                <div>
                    <div className="relative flex items-start">
                        <div className="flex h-5 items-center">
                            <input
                                id="OptInMarketing"
                                name="OptInMarketing"
                                aria-label="Recibir actualizaciones"
                                type="checkbox"
                                required
                                className="focus:ring-lpprimary text-lpprimary h-4 w-4 rounded border-gray-300"
                            />
                        </div>
                        <div className="-mt-1 ml-3 max-w-md">
                            <label htmlFor="comments" className="text-xs text-white">
                                Sí, me gustaría recibir actualizaciones sobre productos y servicios, promociones, y
                                ofertas especiales, noticias y eventos de LeasePlan incluido vía email.
                            </label>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
