'use client'
import { event } from 'utils/gtm'
import Image from 'next/image'
import Link from 'next/link'
import EventAnchor from 'components/global/EventAnchor'

export default function FooterMarcas({ section }) {
    const handeClickMarca = (marca) => {
        event({ event: 'gaEvent', location: `${section}_footer`, event_name: `marca`, detail: marca })
    }

    return (
        <>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/alfa-romeo/" onClick={() => handeClickMarca('alfaromeo')}>
                        <Image
                            src="/assets/icons/brands/alfaromeo.svg"
                            alt="Alfa Romeo"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="32"
                            height="32"
                        />
                        Alfa Romeo
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/aston-martin/" onClick={() => handeClickMarca('astonmartin')}>
                        <Image
                            src="/assets/icons/brands/aston-martin.svg"
                            alt="Aston Martin"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="137"
                            height="32"
                        />
                        Aston Martin
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link href="/ofertas-renting/audi/" passHref onClick={() => handeClickMarca('audi')}>
                        <Image
                            src="/assets/icons/brands/audi.svg"
                            alt="Audi"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="92"
                            height="32"
                        />
                        Audi
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/bentley/" onClick={() => handeClickMarca('bentley')}>
                        <Image
                            src="/assets/icons/brands/bentley.svg"
                            alt="BMW"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="98"
                            height="32"
                        />
                        Bentley
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/bmw/" onClick={() => handeClickMarca('bmw')}>
                        <Image
                            src="/assets/icons/brands/bmw.svg"
                            alt="BMW"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="33"
                            height="32"
                        />
                        BMW
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/citroen/" onClick={() => handeClickMarca('citroen')}>
                        <Image
                            src="/assets/icons/brands/citroen.svg"
                            alt="Citroën"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="37"
                            height="32"
                        />
                        Citroën
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/cupra/" onClick={() => handeClickMarca('cupra')}>
                        <Image
                            src="/assets/icons/brands/crupa.svg"
                            alt="Cupra"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="41"
                            height="32"
                        />
                        Cupra
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/dacia/" onClick={() => handeClickMarca('dacia')}>
                        <Image
                            src="/assets/icons/brands/dacia.svg"
                            alt="Dacia"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="112"
                            height="32"
                        />
                        Dacia
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/ds/" onClick={() => handeClickMarca('ds')}>
                        <Image
                            src="/assets/icons/brands/ds.svg"
                            alt="DS"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="26"
                            height="32"
                        />
                        DS
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/ferrari/" onClick={() => handeClickMarca('ferrari')}>
                        <Image
                            src="/assets/icons/brands/ferrari.svg"
                            alt="Ferrari"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="32"
                            height="32"
                        />
                        Ferrari
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/fiat/" onClick={() => handeClickMarca('fiat')}>
                        <Image
                            src="/assets/icons/brands/fiat.svg"
                            alt="Fiat"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="32"
                            height="32"
                        />
                        Fiat
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/ford/" onClick={() => handeClickMarca('ford')}>
                        <Image
                            src="/assets/icons/brands/ford.svg"
                            alt="Ford"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="94"
                            height="32"
                        />
                        Ford
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/honda/" onClick={() => handeClickMarca('honda')}>
                        <Image
                            src="/assets/icons/brands/honda.svg"
                            alt="Honda"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="39"
                            height="32"
                        />
                        Honda
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/hyundai/" onClick={() => handeClickMarca('hyundai')}>
                        <Image
                            src="/assets/icons/brands/hyundai.svg"
                            alt="Hyundai"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="62"
                            height="32"
                        />
                        Hyundai
                    </Link>
                </div>
            </div>
            <div>
                <div className="h-10 text-center">
                    <Link passHref href="/ofertas-renting/isuzu/" onClick={() => handeClickMarca('isuzu')}>
                        <div className="mb-2 flex h-10 items-center justify-center">
                            <Image
                                src="/assets/icons/brands/isuzu.svg"
                                alt="Isuzu"
                                className="mx-auto h-3 w-auto"
                                height="25"
                                width="144"
                                style={{ filter: 'brightness(0) invert(1)' }}
                            />
                        </div>
                        Isuzu
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/iveco/" onClick={() => handeClickMarca('iveco')}>
                        <Image
                            src="/assets/icons/brandscolor/logo_iveco.svg"
                            alt="Iveco"
                            className="mx-auto mb-2 h-10 w-auto"
                            height="40"
                            width="62"
                            style={{ filter: 'brightness(0) invert(1)' }}
                        />
                        Iveco
                    </Link>
                </div>
            </div>

            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/jaguar/" onClick={() => handeClickMarca('jaguar')}>
                        <Image
                            src="/assets/icons/brands/jaguar.svg"
                            alt="Jaguar"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="83"
                            height="32"
                        />
                        Jaguar
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/jeep/" onClick={() => handeClickMarca('jeep')}>
                        <Image
                            src="/assets/icons/brands/jeep.svg"
                            alt="Jeep"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="77"
                            height="32"
                        />
                        Jeep
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/kia/" onClick={() => handeClickMarca('kia')}>
                        <Image
                            src="/assets/icons/brands/kia.svg"
                            alt="Kia"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="62"
                            height="32"
                        />
                        Kia
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/lamborghini/" onClick={() => handeClickMarca('lamborghini')}>
                        <Image
                            src="/assets/icons/brands/lamborghini.svg"
                            alt="Lamborghini"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="24"
                            height="32"
                        />
                        Lamborghini
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/land-rover/" onClick={() => handeClickMarca('land-rover')}>
                        <Image
                            src="/assets/icons/brands/land-rover.svg"
                            alt="Land Rover"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="61"
                            height="32"
                        />
                        Land Rover
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/lexus/" onClick={() => handeClickMarca('lexus')}>
                        <Image
                            src="/assets/icons/brands/lexus.svg"
                            alt="Lexus"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="43"
                            height="32"
                        />
                        Lexus
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/mahindra/" onClick={() => handeClickMarca('mahindra')}>
                        <Image
                            src="/assets/icons/brands/mahindra.svg"
                            alt="Mahindra"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="67"
                            height="32"
                        />
                        Mahindra
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/mazda/" onClick={() => handeClickMarca('mazda')}>
                        <Image
                            src="/assets/icons/brands/mazda.svg"
                            alt="Mazda"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="32"
                            height="32"
                        />
                        Mazda
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    {/*
                <EventAnchor
                        onClick={() => {
                            handeClickMarca('maserati')
                            window.open(
                                'https://www.leaseplan.com/es-es/maserati-grecale-renting-precios-y-especificaciones/',
                                'targetWindow',
                                'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1280,height=720'
                            )
                        }}
                        href="javscript:void(0)"
                    >
                     */}
                    <Link passHref href="/ofertas-renting/maserati/" onClick={() => handeClickMarca('maserati')}>
                        <Image
                            src="/assets/icons/brands/maserati.svg"
                            alt="Maserati"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="67"
                            height="32"
                        />
                        Maserati
                    </Link>
                    {/* </EventAnchor> */}
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link
                        passHref
                        href="/ofertas-renting/mercedes-benz/"
                        onClick={() => handeClickMarca('mercedes-benz')}
                    >
                        <Image
                            src="/assets/icons/brands/mercedes-benz.svg"
                            alt="Mercedes-Benz"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="32"
                            height="32"
                        />
                        Mercedes-Benz
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/mg/" onClick={() => handeClickMarca('mg')}>
                        <Image
                            src="/assets/icons/brands/mg.svg"
                            alt="MG"
                            className="mx-auto mb-2 h-8 w-auto brightness-0 invert"
                            width="50"
                            height="50"
                        />
                        MG
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/mini/" onClick={() => handeClickMarca('mini')}>
                        <Image
                            src="/assets/icons/brands/mini.svg"
                            alt="Mini"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="71"
                            height="32"
                        />
                        Mini
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/mitsubishi/" onClick={() => handeClickMarca('mitsubishi')}>
                        <Image
                            src="/assets/icons/brands/mitsubishi.svg"
                            alt="Mitsubishi"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="37"
                            height="32"
                        />
                        Mitsubishi
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/nissan/" onClick={() => handeClickMarca('nissan')}>
                        <Image
                            src="/assets/icons/brands/nissan.svg"
                            alt="Nissan"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="40"
                            height="32"
                        />
                        Nissan
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/opel/" onClick={() => handeClickMarca('opel')}>
                        <Image
                            src="/assets/icons/brands/opel.svg"
                            alt="Opel"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="42"
                            height="32"
                        />
                        Opel
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/peugeot/" onClick={() => handeClickMarca('peugeout')}>
                        <Image
                            src="/assets/icons/brands/peugeot.svg"
                            alt="Peugeot"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="31"
                            height="32"
                        />
                        Peugeot
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/polestar/" onClick={() => handeClickMarca('polestar')}>
                        <Image
                            src="/assets/icons/brands/polestar.svg"
                            alt="Polestar"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="31"
                            height="32"
                        />
                        Polestar
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/porsche/" onClick={() => handeClickMarca('porsche')}>
                        <Image
                            src="/assets/icons/brands/porsche.svg"
                            alt="Porsche"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="26"
                            height="32"
                        />
                        Porsche
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/renault/" onClick={() => handeClickMarca('renault')}>
                        <Image
                            src="/assets/icons/brands/renault.svg"
                            alt="Renault"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="25"
                            height="32"
                        />
                        Renault
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/rolls-royce/" onClick={() => handeClickMarca('rolls-royce')}>
                        <Image
                            src="/assets/icons/brands/rolls-royce.svg"
                            alt="Rolls Royce"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="21"
                            height="32"
                        />
                        Rolls Royce
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/ssangyong" onClick={() => handeClickMarca('ssangyong')}>
                        <Image
                            src="/assets/icons/brands/sangyong.svg"
                            alt="SsangYong"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="53"
                            height="32"
                        />
                        SsangYong
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/seat/" onClick={() => handeClickMarca('seat')}>
                        <Image
                            src="/assets/icons/brands/seat.svg"
                            alt="Seat"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="36"
                            height="32"
                        />
                        Seat
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/skoda/" onClick={() => handeClickMarca('skoda')}>
                        <Image
                            src="/assets/icons/brands/skoda.svg"
                            alt="Skoda"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="32"
                            height="32"
                        />
                        Skoda
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/smart/" onClick={() => handeClickMarca('smart')}>
                        <Image
                            src="/assets/icons/brands/smart.svg"
                            alt="Skoda"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="114"
                            height="32"
                        />
                        Smart
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/subaru/" onClick={() => handeClickMarca('subaru')}>
                        <Image
                            src="/assets/icons/brands/subaru.svg"
                            alt="Skoda"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="55"
                            height="32"
                        />
                        Subaru
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/suzuki/" onClick={() => handeClickMarca('suzuki')}>
                        <Image
                            src="/assets/icons/brands/suzuki.svg"
                            alt="Skoda"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="32"
                            height="32"
                        />
                        Suzuki
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/tesla/" onClick={() => handeClickMarca('tesla')}>
                        <Image
                            src="/assets/icons/brands/tesla.svg"
                            alt="Tesla"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="32"
                            height="32"
                        />
                        Tesla
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/toyota/" onClick={() => handeClickMarca('toyota')}>
                        <Image
                            src="/assets/icons/brands/toyota.svg"
                            alt="Toyota"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="51"
                            height="32"
                        />
                        Toyota
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/volkswagen/" onClick={() => handeClickMarca('volkswagen')}>
                        <Image
                            src="/assets/icons/brands/volkswagen.svg"
                            alt="Volkswagen"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="33"
                            height="32"
                        />
                        Volkswagen
                    </Link>
                </div>
            </div>
            <div>
                <div className="text-center">
                    <Link passHref href="/ofertas-renting/volvo" onClick={() => handeClickMarca('volvo')}>
                        <Image
                            src="/assets/icons/brands/volvo.svg"
                            alt="Volvo"
                            className="mx-auto mb-2 h-8 w-auto"
                            width="32"
                            height="32"
                        />
                        Volvo
                    </Link>
                </div>
            </div>
        </>
    )
}
