export function isBot(userAgent) {
    const bots = [
        /bot/i,
        /crawl/i,
        /spider/i,
        /Googlebot/i,
        /Bingbot/i,
        /Slurp/i,
        /DuckDuckBot/i,
        /Baiduspider/i,
        /YandexBot/i,
        /facebot/i,
        /facebookexternalhit/i,
        /Twitterbot/i,
        /Lighthouse/i,
        /PageSpeed Insights/i, // Detección de PageSpeed Insights
    ]

    const result = bots.some((botRegex) => botRegex.test(userAgent))
    return result
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const mergeObjects = function (target, source) {
    Object.keys(source).forEach((key) => {
        const sourceValue = source[key]
        const targetValue = target[key]
        target[key] = mergeValues(targetValue, sourceValue)
    })

    return target
}

export const mergeArrays = function (target, source) {
    source.forEach((value, index) => {
        target[index] = mergeValues(target[index], value)
    })

    return target
}

export const isObject = function (obj) {
    return (typeof obj === 'object' && obj !== null) || typeof obj === 'function'
}

export const mergeValues = function (target, source) {
    if (isObject(target) && isObject(source)) {
        return mergeObjects(target, source)
    }
    if (Array.isArray(target) && Array.isArray(source)) {
        return mergeArrays(target, source)
    }
    if (source === undefined) {
        return target
    }
    return source
}

export const shuffleArray = function (array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
    }
}

function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement('textarea')
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        console.log('Fallback: Copying text command was ' + msg)
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
    }

    document.body.removeChild(textArea)
}
export const copyTextToClipboard = function (text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
    }
    navigator.clipboard.writeText(text).then(
        function () {
            alert('Enlace copiado correctamente.')
            console.log('Async: Copying to clipboard was successful!')
        },
        function (err) {
            console.error('Async: Could not copy text: ', err)
        }
    )
}

export const debounce = function (func, wait, immediate) {
    let timeout
    return function () {
        const context = this
        const args = arguments
        const later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }
        const callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}
export const removeKeys = (obj, keys) =>
    obj !== Object(obj)
        ? obj
        : Array.isArray(obj)
        ? obj.map((item) => removeKeys(item, keys))
        : Object.keys(obj)
              .filter((k) => !keys.includes(k))
              .reduce((acc, x) => Object.assign(acc, { [x]: removeKeys(obj[x], keys) }), {})
