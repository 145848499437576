"use client"
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes, faUserCircle, faAngleDown, faPhoneAlt, faChevronRight, faPhone, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

import { classNames } from 'utils/global'

import Link from 'next/link'

import { event } from 'utils/gtm'

import Script from 'next/script'

export default function Header({ active, section, light }) {
  const clsActive = 'text-lpprimary hover:text-lpprimary'
  const clsDefault = 'text-lpneutral40 hover:text-lpprimary '

  const handleClickLogo = () => {
    event(
      { 'event': 'gaEvent', 'location': section, 'event_name': 'barra_superior', 'detail': 'logo' }
    )
  }
  const handleClickSection = (to) => {
    event(
      { 'event': 'gaEvent', 'location': section, 'event_name': 'barra_superior', 'detail': to }
    )
  }

  const handleClickTeLlamamos = () => {
    event(
      { 'event': 'gaEvent', 'location': section, 'event_name': 'barra_superior', 'detail': 'te llamamos gratis' }
    )
  }

  const handleClickContacto = () => {
    event(
      { 'event': 'gaEvent', 'location': section, 'event_name': 'barra_superior', 'detail': 'email' }
    )
  }

  const handleClickContacto2 = () => {
    event(
      { 'event': 'gaEvent', 'location': section, 'event_name': 'barra_superior', 'detail': 'telefono' }
    )
  }

  const handleOficinaOnline = () => {
    event(
      { 'event': 'gaEvent', 'location': section, 'event_name': 'barra_superior', 'detail': 'oficina online' }
    )
  }

  const handleMyGo = () => {
    event(
      { 'event': 'gaEvent', 'location': section, 'event_name': 'barra_superior', 'detail': 'mygo' }
    )
  }


  const handleClickAreaPersonal = () => {
    event(
      { 'event': 'gaEvent', 'location': section, 'event_name': 'barra_superior', 'detail': 'area personal' }
    )
  }

  const handleClickTelefonos = (tipo_telefono) => {
    event(
      { 'event': 'gaEvent', 'location': section, 'event_name': 'barra_superior', 'detail': tipo_telefono }
    )
  }


  return <>
    <Disclosure as="nav" className="bg-white shadow mb-8 overflow-hidden md:overflow-visible">
      {({ open }) => (
        <>
          <div className="max-w-[1440px] mx-auto px-4 xl:px-2">
            <div className="flex justify-between h-16">
              <div className="flex flex-grow">
                <div className="w-[200px] relative">
                  <Link href="/" onClick={handleClickLogo}>

                    <img
                      className="block md:hidden absolute top-[-30px] left-[-40px] h-[124px] w-[195px]"
                      src="/assets/logos/big-logo.png"
                      alt="LeasePlan Go"
                    />
                    <img
                      className="hidden md:block absolute top-[-26px] left-0 h-[124px] w-[195px] z-10"
                      src="/assets/logos/big-logo.png"
                      alt="LeasePlan Go"
                    />
                  </Link>
                </div>
                <div className="flex items-center justify-center md:hidden">
                  <div className="rounded-full bg-lpbackground inline-block p-3"><a href="tel:+34912995866" aria-label="Llámanos"><img src={'/assets/icons/leaseplan/phone-coming.svg'} className="w-6 h-6" alt="" /></a></div>
                </div>
                <div className="hidden flex-grow md:ml-5 md:flex md:space-x-2 lg:space-x-3">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  <Link
                    href="/particulares/ofertas-renting/"
                    passHref
                    onClick={() => handleClickSection('particulares')}
                    className={`${active === 'Particulares' ? clsActive : clsDefault} inline-flex items-center px-1 pt-1 text-xs lg:text-sm font-semibold`}>
                    Renting Particulares

                  </Link>
                  <Link
                    href="/empresas/ofertas-renting/"
                    passHref
                    onClick={() => handleClickSection('empresas')}
                    className={`${active === 'Empresas' ? clsActive : clsDefault} inline-flex items-center px-1 pt-1 text-xs lg:text-sm font-semibold`}>

                    Renting Empresas
                  </Link>
                  <Link
                    href="/autonomos/ofertas-renting/"
                    passHref
                    onClick={() => handleClickSection('autonomos')}
                    className={`${active === 'Autónomos' ? clsActive : clsDefault} inline-flex items-center px-1 pt-1 text-xs lg:text-sm font-semibold`}>
                    Renting Autónomos
                  </Link>
                  <Link
                    href="/renting/"
                    passHref
                    onClick={() => handleClickSection('ofertas')}
                    className={`${active === 'Coches' ? clsActive : clsDefault} inline-flex items-center px-1 pt-1 text-xs lg:text-sm font-semibold`}>
                    Coches
                  </Link>
                  {/*
                                    <Link href="/l/renting-de-motos/" passHref><a onClick={() => handleClickSection('motos')}
                                        className={`${active === 'Motos' ? clsActive : clsDefault} inline-flex items-center px-1 pt-1 text-xs lg:text-sm font-semibold`}
                                    >
                                        Motos
                                    </a></Link>
                                    */}
                  {/*
                                <a
                                    href="#"
                                    className="hidden lg:inline-flex text-lpneutral40 hover:text-lpprimary items-center px-1 pt-1 text-xs lg:text-sm font-semibold"
                                >
                                    Calcula tu renting
                                </a>
                                 */}
                </div>
              </div>
              <div className="hidden md:ml-6 md:flex md:items-center gap-x-6">
                <Link href="/contacto?llamada=true" passHref onClick={handleClickTeLlamamos}><button className="hidden xl:block bg-white py-2 px-4 border border-lpprimary rounded-full shadow-sm text-sm leading-4 font-semibold text-lpprimary hover:bg-gray-50">
                  <FontAwesomeIcon icon={faPhoneAlt} className="text-lpprimary mr-2" aria-hidden="true" />
                  Te llamamos GRATIS
                </button></Link>
                <a href="tel:+34912995866" onClick={handleClickContacto2} className="hidden xl:block text-lpprimary font-bold leading-none">
                  91 29 95 866
                </a>

                <Link
                  href="/contacto/?escribenos=true"
                  passHref
                  onClick={handleClickContacto}
                  aria-label="Contacto"
                  className="hidden xl:block">
                  <FontAwesomeIcon icon={faEnvelope} className="text-lpprimary text-xl relative top-0.5" aria-hidden="true" />
                </Link>

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div onClick={handleClickAreaPersonal}>
                    <Menu.Button className="bg-white flex focus:outline-none text-lpprimary items-center justify-center">
                      <span className="sr-only">Abrir menú</span>
                      {/*
                                        <img
                                            className="h-8 w-8 rounded-full"
                                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                            alt=""
                                        />
                                         */}
                      Soy cliente <FontAwesomeIcon icon={faUserCircle} className="text-lpprimary mx-1 text-xl" aria-hidden="true" />
                      <FontAwesomeIcon icon={faAngleDown} className="text-lpprimary" aria-hidden="true" />

                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-72 rounded-xl shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="https://myfleet.leaseplan.es/"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2.5 text-sm text-gray-700')}
                            onClick={handleOficinaOnline}
                          >
                            Acceso Oficina Online <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="text-lpprimary ml-2" aria-hidden="true" />
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="https://clientes.leaseplango.es/configurator/es/leaseplan/mygotrigger.jsp?loc=es_ES&vid=2&appheight=550"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2.5 text-sm text-gray-700')}
                            onClick={handleMyGo}
                          >
                            Acceso MyGo <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="text-lpprimary ml-2" aria-hidden="true" />
                          </a>
                        )}
                      </Menu.Item>
                      <hr className="m-3" />
                      <Menu.Item>
                        {({ active }) => (
                          <span className="block px-4 py-2 text-sm font-semibold text-lpneutral40">
                            <FontAwesomeIcon icon={faPhone} className="mr-1 text-lpprimary " /> Llámanos
                          </span>
                        )}
                      </Menu.Item>

                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="tel:+34912995866"
                            onClick={() => handleClickTelefonos('telefono_contratacion')}
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2.5 text-sm text-gray-700')}
                          >
                            Contratación
                            <span className="ml-2 font-semibold text-lpprimary">912 995 866</span>
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="tel:+34919195999"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2.5 text-sm text-gray-700')}
                            onClick={() => handleClickTelefonos('telefono_atencion_cliente')}
                          >
                            Atención al cliente
                            <span className="ml-2 font-semibold text-lpprimary">919 195 999</span>
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="tel:+34919192999"
                            onClick={() => handleClickTelefonos('telefono_atencion_conductor')}
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2.5 mb-2 text-sm text-gray-700')}
                          >
                            Atención al conductor
                            <span className="ml-2 font-semibold text-lpprimary">919 192 999</span>
                          </a>
                        )}
                      </Menu.Item>

                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="-mr-2 flex items-center md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-lpprimary">
                  <span className="sr-only">Abrir menú</span>
                  {open ? (
                    <FontAwesomeIcon icon={faTimes} className="block text-2xl text-lpprimary" aria-hidden="true" />
                  ) : (
                    <FontAwesomeIcon icon={faBars} className="block text-2xl text-lpprimary" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="pt-10 pb-3 space-y-1">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              <Link
                href="/particulares/ofertas-renting/"
                passHref
                onClick={() => handleClickSection('particulares')}
                className="pl-3 pr-4 py-2 border-l-4 text-base font-semibold text-lpneutral40 flex items-center">
                <span>Renting Particulares</span>
                <span className="ml-auto inline-block pt-0.5"><FontAwesomeIcon icon={faChevronRight} className="text-lpprimary text-xl" aria-hidden="true" /></span>
              </Link>
              <Link
                href="/empresas/ofertas-renting/"
                passHref
                onClick={() => handleClickSection('empresas')}
                className="pl-3 pr-4 py-2 border-l-4 text-base font-semibold text-lpneutral40 flex items-center">Renting Empresas<span className="ml-auto inline-block pt-0.5"><FontAwesomeIcon icon={faChevronRight} className="text-lpprimary text-xl" aria-hidden="true" /></span>
              </Link>
              <Link
                href="/autonomos/ofertas-renting/"
                passHref
                onClick={() => handleClickSection('autonomos')}
                className={`pl-3 pr-4 py-2 border-l-4 text-base font-semibold ${active === 'Autónomos' ? clsActive : clsDefault} flex items-center`}>Renting Autónomos<span className="ml-auto inline-block pt-0.5"><FontAwesomeIcon icon={faChevronRight} className="text-lpprimary text-xl" aria-hidden="true" /></span>
              </Link>
              <Link
                href="/renting/"
                passHref
                onClick={() => handleClickSection('coches')}
                className={`pl-3 pr-4 py-2 border-l-4 text-base font-semibold ${active === 'Coches' ? clsActive : clsDefault} flex items-center`}>Coches<span className="ml-auto inline-block pt-0.5"><FontAwesomeIcon icon={faChevronRight} className="text-lpprimary text-xl" aria-hidden="true" /></span>
              </Link>
              {/*
                            <Link href="/l/renting-de-motos/" passHref><a onClick={() => handleClickSection('motos')}

                                className={`pl-3 pr-4 py-2 border-l-4 text-base font-semibold ${active === 'Motos' ? clsActive : clsDefault} flex items-center`}
                            >
                                Motos
                                <span className="ml-auto inline-block pt-0.5"><FontAwesomeIcon icon={faChevronRight} className="text-lpprimary text-xl" aria-hidden="true" /></span>
                            </a></Link>
                             */}
              {/* <a
                            href="#"
                            className="pl-3 pr-4 py-2 border-l-4 text-base font-semibold text-lpneutral40 flex items-center"
                        >
                            Calcula tu renting
                        </a>
                        */}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="px-8">
                <Link href="/contacto/?llamada=true" passHref onClick={handleClickTeLlamamos}><button className="w-full block bg-white py-2 px-4 border border-lpprimary rounded-full shadow-sm text-sm leading-4 font-semibold text-lpprimary hover:bg-gray-50">
                  <FontAwesomeIcon icon={faPhoneAlt} className="text-lpprimary mr-2" aria-hidden="true" />
                  Te llamamos GRATIS
                </button></Link>

              </div>
              <div className="mt-3 space-y-1">
                <Link
                  href="/contacto/?escribenos=true"
                  passHref
                  onClick={handleClickContacto}
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100">
                  <FontAwesomeIcon icon={faEnvelope} className="text-lpprimary mr-2" aria-hidden="true" />Contacto</Link>
                <a
                  href="https://myfleet.leaseplan.es/"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                  onClick={handleOficinaOnline}
                >
                  <FontAwesomeIcon icon={faUserCircle} className="text-lpprimary mr-2" aria-hidden="true" />
                  Acceso Oficina Online
                  <FontAwesomeIcon icon={faChevronRight} className="text-lpprimary ml-4" aria-hidden="true" />
                </a>
                <a
                  href="https://clientes.leaseplango.es/configurator/es/leaseplan/mygotrigger.jsp?loc=es_ES&vid=2&appheight=550"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                  onClick={handleMyGo}
                >
                  <FontAwesomeIcon icon={faUserCircle} className="text-lpprimary mr-2" aria-hidden="true" />
                  Acceso MyGo
                  <FontAwesomeIcon icon={faChevronRight} className="text-lpprimary ml-4" aria-hidden="true" />
                </a>
                <hr className="m-3" />
                <a
                  href="tel:+34912995866"
                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-3 text-sm text-gray-700')}
                  onClick={() => handleClickTelefonos('telefono_contratacion')}
                >
                  Contratación
                  <span className="ml-2 font-semibold text-lpprimary">912 995 866</span>
                </a>
                <a
                  href="tel:+34919195999"
                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-3 text-sm text-gray-700')}
                  onClick={() => handleClickTelefonos('telefono_atencion_cliente')}
                >
                  Atención al cliente
                  <span className="ml-2 font-semibold text-lpprimary">919 195 999</span>
                </a>
                <a
                  href="tel:+34919192999"
                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-3 mb-2 text-sm text-gray-700')}
                  onClick={() => handleClickTelefonos('telefono_atencion_conductor')}
                >
                  Atención al conductor
                  <span className="ml-2 font-semibold text-lpprimary">919 192 999</span>
                </a>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  </>;
}
