export default function Button({ variant, size, block, children, className, href, target, rel, ...props }) {
    let sizeClass = 'text-sm px-6 pt-2.5 pb-2 '
    let extraClass = ''
    let variantClass = ''

    switch (variant) {
        case 'active':
            variantClass = 'degPrimary text-white border-none'
            break
        case 'blue':
            variantClass = 'degLightBlue text-white border-none'
            break
        case 'simple':
            variantClass = 'border-lpprimary text-lpprimary'
            break
        case 'white':
            variantClass = 'border-white text-lpprimary bg-white'
            break
        default:
            variantClass = 'text-white border-white border-2'
    }

    switch (size) {
        case 'xl':
            sizeClass = 'text-xl px-8 pt-3 pb-2.5 '
            break
        case 'lg':
            sizeClass = 'text-lg px-4 pt-2 pb-1.5'
            break
    }

    if (block) {
        extraClass += ' block w-full'
    }

    // TODO: only one return with dynamic children type
    if (href) {
        return (
            <a
                href={href}
                target={target}
                rel={rel}
                className={`rounded-full border text-center ${sizeClass} ${variantClass} ${extraClass} ${className}`}
                {...props}
            >
                {children}
            </a>
        )
    }
    return (
        <button
            className={`rounded-full border text-center disabled:opacity-50 ${sizeClass} ${variantClass} ${extraClass} ${className}`}
            {...props}
        >
            {children}
        </button>
    )
}
